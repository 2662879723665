
import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';


export function percentageValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (value === null || value === undefined || value === '') {
      return null; // Don't validate empty values to allow other validators to handle 'required'
    }

    const isValid = !isNaN(value) && value >= 0 && value <= 100;
    return isValid ? null : { percentage: true };
  };
}

export function numberValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (value === null || value === undefined || value === '') {
      return null; // Allow other validators to handle empty values
    }

    const isValid = !isNaN(value) && typeof +value === 'number';
    return isValid ? null : { number: true };
  };
}

export function integerValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (value === null || value === undefined || value === '') {
      return null; // Allow other validators to handle empty values
    }

    const isInteger = Number.isInteger(+value);
    return isInteger ? null : { integer: true };
  };
}

function stripTime(date: Date): Date {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0); // Set time to midnight (00:00:00)
  return newDate;
}

// Custom maxDate validator function
export function maxDate(max: Date): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const controlValue = stripTime(new Date(control.value));
    const maxDateValue = stripTime(max);

    // Check if control value is greater than the max date (date only)
    if (controlValue > maxDateValue) {
      return { max: true }; // return error if validation fails
    }
    return null; // return null if validation passes
  };
}

// Custom minDate validator function
export function minDate(min: Date): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const controlValue = stripTime(new Date(control.value));
    const minDateValue = stripTime(min);

    // Check if control value is less than the min date (date only)
    if (controlValue < minDateValue) {
      return { min: true }; // return error if validation fails
    }
    return null; // return null if validation passes
  };
}
// custom underscore validation

export function noUnderscoreValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const hasUnderscore = control.value?.includes('_');
    return hasUnderscore ? { noUnderscore: true } : null;
  };
}

// Common Validators
export const requiredValidator = { name: 'required', validator: Validators.required };
export const maxLengthValidator = (max: number, message: string) => ({ name: 'maxlength', validator: Validators.maxLength(max), message });

// Reusable Fields
export const statusField = {
  type: 'status_toggler',
  label: 'Status',
  name: 'is_active',
  labelReq: true,
};

export const hiddenIdField = {
  type: 'hidden',
  label: 'ID',
  name: 'id',
  labelReq: false,
  visible: false,
};

export interface ValidatorConfig {
  name: string;
  validator: any;
  message?: string;
}

export interface FormField {
  type: string;
  label: string;
  name: string;
  altName?: string;
  class?: string;
  options?: any;  // For select and radio buttons
  apiEndpoint?: string;     // API endpoint to fetch options dynamically
  validators?: ValidatorConfig[];
  labelReq?: boolean;
  meta?: {};
  readonly?: boolean;
  defaultValue?: any;
  controlsDisabling?: Boolean;
  visible?: boolean;
  dependsOn?: string | string[];
  columns?: number;
  templateRef?:any;
  context?: any;
}

export interface FormSection {
  key: string | null;
  title: string;
  columns?: Number;
  fields: FormField[];
  sectionType: string,
  meta?: any;
  layout?: 'table' | 'form';
}

